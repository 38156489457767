import React from 'react';
import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';
import { AuthenticationProvider } from './Authentication';

const Provider = ({ children }: { children: React.ReactElement }) => (
  <AuthenticationProvider>
    <ReactQueryProvider>
      <ReduxProvider>{children}</ReduxProvider>
    </ReactQueryProvider>
  </AuthenticationProvider>
);

export default Provider;

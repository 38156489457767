import {
  Comment,
  DocumentPdf,
  OtherHorizontal,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button, Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import { useState } from 'react';
import RecrutLiveProfile from 'src/Components/RecrutLiveProfile/RecrutLiveProfile.component';
import { useFeatureFlag } from 'src/Hooks/FeatureFlag/useFeatureFlag';
import { CandidateMemoModal } from 'src/Modals/CandidateMemoModal';
import { FeatureFlag } from 'src/Services/API';
import styles from './TalentInformationHeader.module.scss';
import { Props } from './TalentInformationHeader.types';

const TalentInformationHeader = ({ talent, candidateId }: Props) => {
  const showResumeGeneratorLink = useFeatureFlag(FeatureFlag.RESUME_GENERATOR_LINK);

  const [isMemoModalOpen, setIsMemoModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.titleInfoTalent}>informations talent</div>
      <div className={styles.buttonsContainer}>
        <div className={styles.buttons}>
          <RecrutLiveProfile candidateId={talent?.talentId} text={'consulter dossier talent'} />
          <Menu
            className={classNames(styles.menu)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            trigger={
              <div className={styles.dotContainer}>
                <div>
                  <Button variant="tertiary" size="medium">
                    <OtherHorizontal variant="fill" />
                  </Button>
                </div>
              </div>
            }
          >
            {showResumeGeneratorLink && (
              <MenuItem
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_RL_PUBLIC_URL}/candidate/${candidateId}/resume/qualifications`
                  )
                }
              >
                <DocumentPdf /> générer le CV
              </MenuItem>
            )}
            <MenuItem onClick={() => setIsMemoModalOpen(true)}>
              <Comment /> mémos
              <Badge color={talent?.memos?.some(memo => memo.isBehaviorMemo) ? 'error' : 'blue'}>
                {talent?.memos?.length ?? 0}
              </Badge>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <CandidateMemoModal
        candidateId={candidateId}
        open={isMemoModalOpen}
        onClose={() => setIsMemoModalOpen(false)}
      />
    </div>
  );
};

export default TalentInformationHeader;

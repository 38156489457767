import { Filtres } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useState } from 'react';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import SelectSalesphase from 'src/Components/Selects/SelectSalesphase/SelectSalesphase.component';
import SelectService from 'src/Components/Selects/SelectService';
import SingleDatePickerPopOver from 'src/Components/TopBar/SingleDatePickerPopOver';
import { Salesphase } from 'src/Services/API';
import styles from './FilterMissionsModal.module.scss';
import { Props } from './FilterMissionsModal.types';
import SelectCandidate from 'src/Components/Selects/SelectCandidate/SelectCandidate.component';
import moment from 'moment';
import SelectAgencyConsultant from 'src/Components/Selects/SelectAgencyConsultant';

const FilterMissionsModal = ({
  selectedQualification,
  setSelectedQualification,
  selectedAgencyConsultant,
  setSelectedAgencyConsultant,
  selectedCompany,
  setSelectedCompany,
  selectedService,
  setSelectedService,
  selectedCandidate,
  setSelectedCandidate,
  selectedSalesphases,
  setSelectedSalesphases,
  onCompanySelection,
  onValidateClick,
  onCancelClick,
  date: selectedDate,
  onDateChange,
  open,
}: Props) => {
  const [qualification, setQualification] = useState(selectedQualification);
  const [agencyConsultant, setAgencyConsultant] = useState(selectedAgencyConsultant);
  const [company, setCompany] = useState(selectedCompany);
  const [candidate, setCandidate] = useState(selectedCandidate);
  const [service, setService] = useState(selectedService);
  const [date, setDate] = useState(selectedDate);
  const [salesphases, setSalesphases] = useState<Salesphase[]>(selectedSalesphases);
  const defaultDate = moment();

  const reset = useCallback(() => {
    setQualification(selectedQualification);
    setAgencyConsultant(selectedAgencyConsultant);
    setCompany(selectedCompany);
    setCandidate(selectedCandidate);
    setService(selectedService);
    setDate(defaultDate);
    onDateChange(defaultDate);
    setSalesphases(selectedSalesphases);
  }, [
    selectedQualification,
    selectedAgencyConsultant,
    selectedCompany,
    selectedCandidate,
    selectedService,
    selectedSalesphases,
    onDateChange,
    defaultDate,
  ]);

  const saveFilters = useCallback(() => {
    setSelectedQualification(qualification);
    setSelectedAgencyConsultant(agencyConsultant);
    setSelectedCompany(company);
    setSelectedCandidate(candidate);
    setSelectedService(service);
    setSelectedSalesphases(salesphases);
    onDateChange(date);
    onValidateClick();
  }, [
    setSelectedQualification,
    qualification,
    setSelectedAgencyConsultant,
    agencyConsultant,
    setSelectedCompany,
    company,
    setSelectedCandidate,
    candidate,
    setSelectedService,
    service,
    setSelectedSalesphases,
    salesphases,
    onDateChange,
    date,
    onValidateClick,
  ]);

  return (
    <Modal size="medium" open={open ?? true} onClose={onCancelClick}>
      <ModalContent header={<Filtres />} title="filtres commande">
        <ul className={styles.list}>
          <li className={styles.row}>
            <WithLightTitle title="compte" className={styles.filterRow}>
              <SelectCompany
                canBeReset
                selectedCompany={company}
                setSelectedCompany={selectedCompany => {
                  setCompany(selectedCompany);
                  setService(undefined);
                  if (selectedCompany?.companyId !== undefined)
                    onCompanySelection(selectedCompany.companyId);
                }}
              />
            </WithLightTitle>
            <WithLightTitle title="service" className={styles.filterRow}>
              <SelectService
                disabled={company === undefined}
                selectedService={service}
                setSelectedService={selectedService => {
                  setService(selectedService);
                }}
              />
            </WithLightTitle>
          </li>
          <li className={styles.row}>
            <WithLightTitle title="qualification" className={styles.filterRow}>
              <SelectQualification
                // We specify no society / brand to have the same behavior as OSM
                canBeReset
                selectedQualification={qualification}
                setSelectedQualification={selectedQualification => {
                  setQualification(selectedQualification);
                }}
              />
            </WithLightTitle>
            <WithLightTitle title="suivi commercial par" className={styles.filterRow}>
              <SelectAgencyConsultant
                selectedAgencyConsultant={agencyConsultant}
                setSelectedAgencyConsultant={selectedAgencyConsultant => {
                  setAgencyConsultant(selectedAgencyConsultant);
                }}
              />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="nom du talent" className={styles.filter}>
              <SelectCandidate
                canBeReset
                selectedCandidate={candidate}
                setSelectedCandidate={selectedCandidate => {
                  setCandidate(selectedCandidate);
                }}
              />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="statut de la commande" className={styles.filter}>
              <SelectSalesphase selectedPhases={salesphases} onSelectionChange={setSalesphases} />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="afficher les commandes en cours le :" className={styles.filter}>
              <div className={styles.datePickerContainer}>
                <SingleDatePickerPopOver
                  calendar={true}
                  onChange={date => {
                    setDate(date);
                  }}
                />
              </div>
            </WithLightTitle>
          </li>
        </ul>
      </ModalContent>
      <ModalActions side="left">
        <Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>
      </ModalActions>
      <ModalActions side="right">
        <Button.Secondary onClick={onCancelClick}>annuler</Button.Secondary>
        <Button.Primary onClick={saveFilters}>valider</Button.Primary>
      </ModalActions>
    </Modal>
  );
};

export default FilterMissionsModal;

import React, { useEffect } from 'react';

import styles from './../Context.module.scss';
import {
  HorizontalCardWithTitle,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import { Laterality } from 'src/Services/API';
import { TemporaryWorkerProps } from '../Context.types';
import { useController } from 'react-hook-form';
import { useFetchCandidateSensibilisationTesting } from 'src/Hooks/Candidate/useFetchCandidateSensibilisationTesting';

import moment from 'moment';
const lateralityValues = [
  { key: Laterality.LEFT_HANDED, label: 'gaucher' },
  { key: Laterality.RIGHT_HANDED, label: 'droitier' },
  { key: Laterality.UNKNOWN, label: 'info inconnue' },
];

const TemporaryWorker = ({
  isEditable,
  candidateId,
  candidateLastName,
  candidateFirstName,
  control,
  handleSubmit,
}: TemporaryWorkerProps) => {
  const fetchCandidateTesting = useFetchCandidateSensibilisationTesting(candidateId);
  const seniorityAtClient = useController({ control, name: 'seniorityAtClient' });
  const seniorityAtRandstad = useController({ control, name: 'seniorityAtRandstad' });
  const laterality = useController({ control, name: 'laterality' });

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);
  if (seniorityAtClient.field.value && seniorityAtClient.field.value < 0) {
    seniorityAtClient.field.value = 0;
  }
  if (seniorityAtRandstad.field.value && seniorityAtRandstad.field.value < 0) {
    seniorityAtRandstad.field.value = 0;
  }

  return (
    <div>
      <div className={styles.subTitle}>salarié intérimaire</div>
      <div className={styles.subSection}>
        <WithLightTitle
          title="nom"
          className={styles.lightTitle}
          children={<div>{candidateLastName}</div>}
        />
        <WithLightTitle
          title="prénom"
          className={styles.lightTitle}
          children={<div>{candidateFirstName}</div>}
        />
      </div>
      <div className={styles.subSection} onBlur={handleSubmit()}>
        <WithLightTitle
          title="ancienneté dans l’entreprise utilisatrice (en mois)"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <TextInput
                type="number"
                value={seniorityAtClient.field.value}
                onValueChange={value => seniorityAtClient.field.onChange(+value)}
              />
            ) : (
              <div>{seniorityAtClient.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <WithLightTitle
          title="ancienneté chez Randstad (en mois)"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <TextInput
                type="number"
                value={seniorityAtRandstad.field.value ?? 'non renseigné'}
                onValueChange={value => seniorityAtRandstad.field.onChange(+value)}
              />
            ) : (
              <div>{seniorityAtRandstad.field.value ?? 'non renseigné'}</div>
            )
          }
        />
      </div>
      <div className={styles.subSection}>
        <WithLightTitle
          title="latéralité"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <ExclusiveSelectionGroup
                name="laterality"
                className={styles.multipleChoices}
                getSelectedFormValue={value => value?.key}
                getKey={({ key }) => key}
                getStringValue={({ label }) => label}
                values={lateralityValues}
                control={control}
                getSelectedValue={value => lateralityValues.find(({ key }) => key === value)}
              />
            ) : (
              <div>
                {lateralityValues.find(({ key }) => key === laterality.field.value)?.label ??
                  'non renseigné'}
              </div>
            )
          }
        />
      </div>
      <div className={styles.subSection}>
        <WithLightTitle
          title="sensibilisation sécurité suivie"
          className={styles.lightTitleWithFullWidth}
          children={
            <div className={styles.eTestingCardsContainer}>
              {fetchCandidateTesting.data && fetchCandidateTesting.data.length > 0
                ? fetchCandidateTesting.data?.map(eTesting => (
                    <HorizontalCardWithTitle
                      className={styles.eTestingCard}
                      title={eTesting.label ?? 'sensibilisation non renseignée'}
                      subtitles={[
                        `${eTesting.goal?.label ?? 'objectif non renseigné'} | ${
                          eTesting.completedOn
                            ? `passé le 
                      ${moment(eTesting.completedOn).format('DD/MM/YYYY')}`
                            : 'date non renseignée'
                        } - ${
                          eTesting.completionRate
                            ? `${eTesting.completionRate} de réussite`
                            : 'taux de réussite non renseigné'
                        }`,
                      ]}
                    />
                  ))
                : 'aucune sensibilisation sécurité suivie'}
            </div>
          }
        />
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default TemporaryWorker;

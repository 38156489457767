import { RandstadCircle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ParametersPopup as ParametersPopupPrimitive,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { PerimeterSelectionModal } from 'src/Modals/PerimeterSelectionModal';
import styles from './ParametersPopup.module.scss';
import { ParametersPopupProps } from './ParametersPopup.types';
import { useAuth0 } from '@auth0/auth0-react';

export const ParametersPopup = (props: ParametersPopupProps) => {
  const { logout } = useAuth0();
  const user = useAuthenticatedUser();

  const ref = useRef<PopoverActions>(null);
  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <>
      <ParametersPopupPrimitive
        onClose={() => setIsPerimeterModalOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
        firstName={user.givenName}
        lastName={user.familyName}
        email={user.email}
        onLogout={() =>
          logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI } })
        }
        {...props}
        ref={ref}
      >
        <Button.Tertiary
          className={styles.button}
          onClick={() => {
            ref.current?.close();
            setIsPerimeterModalOpen(true);
          }}
        >
          <RandstadCircle variant="fill" />
          choix de vos unités
        </Button.Tertiary>
      </ParametersPopupPrimitive>
      <PerimeterSelectionModal
        open={isPerimeterModalOpen}
        onClose={() => setIsPerimeterModalOpen(false)}
      />
    </>
  );
};

import { Props } from './MissionTalents.types';
import styles from './MissionTalents.module.scss';
import CandidateCard from 'src/Components/CandidateCard';
import React, { useCallback } from 'react';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { UserAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

const MissionTalents = ({ mission, isRepositioningTalent }: Props) => {
  const suggestedOnlyCandidate = mission?.suggestedCandidates?.filter(
    candidate =>
      !mission.candidates?.map(candidate => candidate.candidateId)?.includes(candidate.candidateId)
  );

  const redirectRecrutLiveURL = useCallback(() => {
    const baseUrl = process.env.REACT_APP_RL_PUBLIC_URL + '/?missionId=' + mission?.id;
    window.open(baseUrl);
  }, [mission?.id]);

  return (
    <div className={styles.container}>
      <div className={styles.row}></div>
      <div>
        {suggestedOnlyCandidate !== undefined && suggestedOnlyCandidate.length > 0 && (
          <div className={styles.suggestedContainer}>
            <div className={styles.suggestedCandidate}>suggérés par le CGC</div>
            <div
              className={styles.spaceBetweenLine}
              style={{ display: 'flex', flexDirection: 'row', alignContent: 'stretch' }}
            >
              <p className={styles.subtitle}>nom intérimaire</p>
              <p className={styles.subtitle} />
            </div>
            <div className={styles.separator} />
            {suggestedOnlyCandidate?.map(candidate => (
              <div key={candidate.candidateId}>
                <CandidateCard
                  candidate={candidate}
                  positionStudyId={mission?.positionStudyId}
                  suggestedCandidate
                  hideAcceptance
                  missionId={mission?.id}
                />
                <div className={styles.separator} />
              </div>
            ))}
          </div>
        )}
        <div className={styles.linkedCandidateContainer}>
          <div className={styles.linkedCandidate}>rattaché(e)s sur cette commande</div>
          {isRepositioningTalent && (
            <Button.Secondary className={styles.addCandidate} onClick={redirectRecrutLiveURL}>
              <UserAdd variant="line" />
            </Button.Secondary>
          )}
        </div>
        {mission?.candidates !== undefined && mission.candidates.length > 0 && (
          <>
            <div
              className={styles.spaceBetweenLine}
              style={{ display: 'flex', flexDirection: 'row', alignContent: 'stretch' }}
            >
              <p className={styles.subtitle}>nom intérimaire</p>
              <p className={styles.subtitle}>statuts commande</p>
              <p className={styles.subtitle}>dernière action talent</p>
              <p className={styles.subtitle} />
            </div>
            <div className={styles.separator} />
            {mission.candidates?.map(candidate => (
              <div key={candidate.candidateId}>
                <CandidateCard
                  candidate={candidate}
                  positionStudyId={mission.positionStudyId}
                  missionId={mission.id}
                />
                <div className={styles.separator} />
              </div>
            ))}
          </>
        )}
        {mission?.candidates !== undefined && mission.candidates.length === 0 && (
          <div className={styles.noLinkedTT}>aucun talent rattaché sur cette commande</div>
        )}
      </div>
    </div>
  );
};

export default MissionTalents;
